/**
 * Acronym for organization or top level namespace.
 */
export default {
  PROD: 'prod',
  QA: 'qa',
  DEV: 'dev',
  GOLDEN: 'golden',
  PREPROD: 'preprod',
  E2E: 'e2e',
  TEST: 'test' // integration test also
};
