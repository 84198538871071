/* eslint no-underscore-dangle: ["error", { "allow": ["_getParameterByName", "_splunkIt"] }] */
/* global ECS_LIB_BUILD_VERSION */
import { getCookieValue } from '@appfabric/tracking-core/src/utils/cookieUtils';
import env from '../constants/env';
/* istanbul ignore file */

const e2eURL = 'https://logging-e2e.api.intuit.com/v2/log/message';
const prodURL = 'https://logging.api.intuit.com/v2/log/message';
const preprodApiKey = 'preprdakyres6dF8hkg2meLkckKZt6QjgHdpi4Ll';
const prodApiKey = 'prdakyresvV5u8cSivv3so3GRaYFhdu22H2NUJEn';
const oilWebAppID = 'Intuit.data.entdataproc.trackstarwebapp';
const time = Math.floor(Date.now() / 1000);

/**
 * Constants to differentiate purposes.
 * @type {{WARN: string, INFO: string, ERROR: string}}
 */
export const LOG_TYPE = {
  WARN: 'WARN',
  INFO: 'INFO',
  ERROR: 'ERROR'
};

/**
 * Define the environment using in logger
 * Initialize env to 'e2e'
 * @type {{env: string}}
 */
export const loggerEnv = {
  env: 'e2e'
};

/**
 * Define the oilDestinations to broadcast the log message
 * oilDestinations is initialized to 'trackstar-project'
 * @type {{oilLogDestinations: Array}}
 */
export const oilLogDestinations = ['Intuit.data.entdataproc.trackstarwebapp'];

/**
 * Only send error logs to Splunk in prod.
 * Send every type of logs to Splunk in preprod.
 * @param {string} type type of log message
 * @param {object} message message string
 * @param {string} userEnv environment where user makes the call
 * @param {boolean} broadcastLogsToClient the boolean to broadcast to client splunk
 * @private
 */
const _splunkIt = (type, message, userEnv, broadcastLogsToClient) => {
  const url = window.location.href;
  const encodeUrl = url ? encodeURI(url) : url;

  const spanId = '7afa70fe-f1cc-11e7-8c3f-9a214cf093ae';
  const oilProps = { timestamp: `${time}`, destination: [] };
  if (broadcastLogsToClient && oilLogDestinations.length > 1) {
    oilProps.destination.push(oilLogDestinations);
  } else {
    delete oilProps.destination;
  }
  let err = '';
  if (type === LOG_TYPE.ERROR) {
    err = new Error();
  }
  const payload = [
    {
      eventTypes: 'APPLICATION_CLIENT',
      spanId,
      parentSpanId: spanId,
      rootSpanId: spanId,
      transactionId: 'tid1',
      env: userEnv,
      deviceId: 'DeviceId1',
      logType: type,
      appId: 'appId1',
      component: 'component',
      url: encodeUrl,
      userId: 'TrackStar',
      ivid: getCookieValue('ivid') || getCookieValue('provisional_ivid') || '',
      trackingLibraryVersion: ECS_LIB_BUILD_VERSION,
      callStack: err ? err.stack : '',
      oilProps,
      execTimeMs: 100,
      Message: message
    }
  ];

  let oilURL = '';
  let oilAppID = '';
  let apiKey = '';

  oilAppID = oilWebAppID;
  if (userEnv === env.PROD) {
    oilURL = prodURL;
    apiKey = prodApiKey;
  } else {
    oilURL = e2eURL;
    apiKey = preprodApiKey;
  }

  fetch(oilURL, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Intuit_APIKey intuit_apikey=${apiKey}, intuit_apkey_version=1.0`,
      '-X-APPID': `'${oilAppID}'`,
      '-X-DEVICEID': 'a',
      '-X-INTUIT_TID': 'a'
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error during logging with status code: ${response.status}`);
      }
    })
    .catch((error) => {
      /* eslint-disable no-console */
      console.log(error);
      /* eslint-enable no-console */
    });
};

/**
 * Retrieve a URL paramater from a URL.
 * @param {string} paramName name of the param
 * @param {string} paramUrl the url
 * @returns {string} the parameter corresponding to paramName
 * @private
 */
const _getParameterByName = (paramName, paramUrl) => {
  let url;
  if (paramUrl) {
    url = paramUrl;
  } else {
    url = window.location.href;
  }
  let name = paramName;
  name = name.replace(/\[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Take in a log type, a message object, an environment and an org value.
 * Then it sends to the proper Splunk server.
 * To enable console logging in prod. set debug flag to 1.
 * @param {string} type - type of log message
 * @param {string} file - name of the file the log is in
 * @param {string} method - name of the method the log is called in
 * @param {string} msg - message to attach to the log
 * @param {string} [trackingApplication] - application being tracked
 * @param {boolean} broadcastLogsToClient the boolean to broadcast to client splunk
 * @param {string} logPerSession a string key to verify if this message has been logged before and not log it again during the current session.
 */
export const log = (
  type,
  file,
  method,
  msg,
  trackingApplication,
  broadcastLogsToClient = false,
  logPerSession = null
) => {
  const message = {
    file,
    method,
    msg,
    trackingApplication: trackingApplication || 'noApp'
  };

  // eslint-disable-next-line require-jsdoc
  const logSplunk = () => _splunkIt(type, message, loggerEnv.env, broadcastLogsToClient);
  const isDebugMode = _getParameterByName('debug') === '1';
  let alreadyLogged = false;
  if (logPerSession && typeof logPerSession === 'string' && logPerSession.length > 0) {
    alreadyLogged = sessionStorage.getItem(`tracking_error_${logPerSession}`);
  }
  // eslint-disable-next-line no-console
  if (!alreadyLogged) {
    if (loggerEnv.env === env.PROD) {
      if (type === LOG_TYPE.ERROR) {
        logSplunk();
        logPerSession &&
          typeof logPerSession === 'string' &&
          sessionStorage.setItem(`tracking_error_${logPerSession}`, 'true');
      }
    } else {
      logSplunk();
      logPerSession &&
        typeof logPerSession === 'string' &&
        sessionStorage.setItem(`tracking_error_${logPerSession}`, 'true');
    }
  }
  if (isDebugMode) {
    // eslint-disable-next-line no-console
    console.log(message);
  }
};
