import { bootstrapWebAnalytics } from './bootstrapWebAnalytics';
import { featureDetect, featureDetectForFunctions } from './utils/browserUtils';

/**
 * booststrap library with polyfill
 * @param {string} writeKey a string of the write key
 * @param {string} env the environment
 * @param {string} loadAdobeVisitorApi whether the user wants to enable AdobeVisitorApi
 * @param {string} bootstrapEvent an event which the library starts to load
 * @param {string} loadModules an Opt-in module to load performance metrics
 * @param {string} nosequencingofcalls tracking-core need not perform call sequencing
 * @param {string} nowait tracking-core need not wait for data such as Adobe MCID
 * @param {string} linkwait milliseconds to wait for fullfilling the call before navigating to anchor tags
 * @param {string} adobeEdgeConfigId Adobe Id for the Web SDK calls - related to the data stream
 * @returns {promise} bootstrapWithPolyfillPromise to use in asynchronous testing
 */
export const bootstrapWithPolyfill = (
  writeKey,
  env,
  loadAdobeVisitorApi,
  bootstrapEvent,
  loadModules,
  nosequencingofcalls,
  nowait,
  linkwait,
  adobeEdgeConfigId
) => {
  const bootstrapWithPolyfillPromise = new Promise((resolveIt) => {
    const missingFeatures = []
      .concat(featureDetect(['Promise', 'fetch']))
      .concat(featureDetectForFunctions(['Event']));
    if (missingFeatures.length === 0) {
      bootstrapWebAnalytics(
        writeKey,
        env,
        loadAdobeVisitorApi,
        bootstrapEvent,
        loadModules,
        nosequencingofcalls,
        nowait,
        linkwait,
        adobeEdgeConfigId
      );
      resolveIt();
    } else {
      window.intuit.tracking.ecs.isBrowserPolyfilled = true;
      /*
       * Setting `gated` will wrap every polyfill within a feature detection, only adding the polyfill if the feature
       * was not detected. https://polyfill.io/v3/api/#request
       */
      const polyfillsUrl = `https://uxfabric.intuitcdn.net/polyfill/polyfill.min.js?features=${missingFeatures.join(
        ','
      )}&flags=gated`;
      const script = document.createElement('script');
      script.onload = () => {
        bootstrapWebAnalytics(
          writeKey,
          env,
          loadAdobeVisitorApi,
          bootstrapEvent,
          loadModules,
          nosequencingofcalls,
          nowait,
          linkwait,
          adobeEdgeConfigId
        );
        if (!window.intuit.tracking.ecs.initBeaconed) {
          document.dispatchEvent(new Event('BootstrapWebAnalytics'));
        }
        resolveIt();
      };
      script.src = polyfillsUrl;
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
    }
  });
  return bootstrapWithPolyfillPromise;
};
export default bootstrapWithPolyfill;
