import enumOrg from '@appfabric/tracking-core/src/constants/org';
import enumPurpose from '@appfabric/tracking-core/src/constants/purpose';
import enumScope from '@appfabric/tracking-core/src/constants/scope';
import enumUiAction from '@appfabric/tracking-core/src/constants/uiAction';
import enumEnv from './env';

export const webAnalyticsMethods = [
  'trackPage',
  'track',
  'identify',
  'group',
  'on',
  'setApplicationTrackingContext',
  'setWidgetTrackingContext'
];

export const webAnalyticsConsants = {
  org: enumOrg,
  purpose: enumPurpose,
  scope: enumScope,
  uiAction: enumUiAction,
  env: enumEnv
};

export const marketingCloudOrgId = '969430F0543F253D0A4C98C6@AdobeOrg';
