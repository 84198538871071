/* eslint-disable prefer-destructuring */
/* multiple of above linter issues  */

import { LOG_TYPE, log, loggerEnv } from './utils/logger';
import { bootstrapWithPolyfill } from './polyfill';
import { webAnalyticsMethods, webAnalyticsConsants } from './constants/trackstarConstants';
import { getDataAttributesFromElement, generateNamespace } from './utils/browserUtils';

/**
 * generateFactoryMethod
 * Define a factory to create stubs. These are placeholders
 * for methods in Analytics.js so that you never have to wait
 * for it to load to actually record data. The `method` is
 * stored as the first argument, so we can replay the data.
 * @param {object} obj - object in which the stub method has to be added
 * @param {string} methodName - method name that needs to be added
 * @returns {void} method name that needs to be added
 *
 * Note: This function assumes that give object as a prpert named q in it whcih is an array.
 */
export const generateFactoryMethod = (obj, methodName) => {
  // eslint-disable-next-line no-param-reassign
  obj[methodName] = (...args) => {
    const parameters = Array.prototype.slice.call(args);
    parameters.unshift(methodName);
    obj.q.push(parameters);
  };
};

/**
 * Exports init function which creates webAnalytics and
 * a queue to record webAnalytics method invocations.
 * @returns {void}
 */
function init() {
  // namespace for webAnalytics library
  generateNamespace('intuit.tracking.ecs.webAnalytics');
  // namespace for webAnalytics constants
  generateNamespace('intuit.tracking.ecs.webAnalytics.constants');

  window.intuit.tracking.ecs.webAnalytics.constants = webAnalyticsConsants;

  // Create a queue, but don't obliterate an existing one!
  const { webAnalytics } = window.intuit.tracking.ecs;
  webAnalytics.q = webAnalytics.q || [];

  // If the snippet was invoked already show an error.
  if (webAnalytics.invoked) {
    log(
      LOG_TYPE.ERROR,
      'init.js',
      '',
      `webAnalytics snippet included twice.`,
      '',
      false,
      'webAnalytics-snippet-twice'
    );
  } else {
    const me = document.querySelector('script[data-name="webAnalytics"]');

    if (me && me.dataset && me.dataset.writeKey && me.dataset.env) {
      const {
        writeKey,
        env,
        loadAdobeVisitorApi,
        bootstrapEvent,
        loadModules,
        nosequencingofcalls,
        nowait,
        linkwait,
        adobeEdgeConfigId,
        perfPerc,
        useRum,
        adobeDisableThirdPartyCalls,
        trackingSamplePercentage,
        enableAep // enableAep is used to enable AEP (Adobe Experience Platform) calls from AlloySender.js
      } = getDataAttributesFromElement(
        me,
        [
          'writeKey',
          'env',
          'loadAdobeVisitorApi',
          'bootstrapEvent',
          'loadModules',
          'nosequencingofcalls',
          'nowait',
          'linkwait',
          'adobeEdgeConfigId',
          'perfPerc',
          'useRum',
          'adobeDisableThirdPartyCalls',
          'trackingSamplePercentage',
          'enableAep'
        ],
        ['loadAdobeVisitorApi', 'loadModules', 'nosequencingofcalls', 'nowait', 'linkwait']
      );

      // Update environment in logger
      loggerEnv.env = env;

      // owndomain indicates customer wants to download all the scripts from his application domain instead of from uxfabric.intuitcdn.net
      const { ownDomain } = getDataAttributesFromElement(me, ['ownDomain'], ['ownDomain']);
      window.intuit.tracking.ecs.ownDomain = ownDomain;
      window.intuit.tracking.ecs.perfPerc = perfPerc;
      window.intuit.tracking.ecs.useRum = useRum;
      window.intuit.tracking.ecs.adobeDisableThirdPartyCalls = adobeDisableThirdPartyCalls;
      window.intuit.tracking.ecs.trackingSamplePercentage = trackingSamplePercentage;
      window.intuit.tracking.ecs.enableAep = enableAep;

      /*
       * Invoked flag, to make sure the snippet
       * is never invoked twice.
       */
      webAnalytics.invoked = true;

      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < webAnalyticsMethods.length; i += 1) {
        const methodName = webAnalyticsMethods[i];
        generateFactoryMethod(webAnalytics, methodName);
      }

      window.analytics = window.analytics || [];
      const { analytics } = window;
      /*
       * TODO check with Segment friends if they can NOT derive initialPageview based on the snippet version being set.
       * https://github.com/segmentio/analytics.js-core/blob/9f562c3946d33a945e56e9de50dee14825b3f98a/lib/analytics.js#L61
       * REMOVING the below line will result in a DUPLICATE page call each time analytics.js library is loaded
       */
      analytics.SNIPPET_VERSION = '4.1.0';

      bootstrapWithPolyfill(
        writeKey,
        env,
        loadAdobeVisitorApi,
        bootstrapEvent,
        loadModules,
        nosequencingofcalls,
        nowait,
        linkwait,
        adobeEdgeConfigId
      );
    } else {
      log(
        LOG_TYPE.ERROR,
        'init.js',
        '',
        'data-name, data-write-key and data-env is required in init script.',
        '',
        false,
        'init-script-required-fields'
      );
    }
  }
}

init();

export { getDataAttributesFromElement, init };
